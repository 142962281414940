import {
  Box,
  Button,
  TextField,
  useTheme,
  Autocomplete,
  Typography,
  Paper,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import { tokens } from "../../theme";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { styled } from "@mui/material/styles";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ClientDialog from "../../components/ClientDialog";
import EditClientDialog from "../../components/EditClientDialog";
import RecipientDialog from "../../components/NewRecipientDialog";
import EditRecipientDialog from "../../components/EditRecipientDialog";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import ProviderLimitDialog from "../../components/ProviderLimitDialog";
import { DataGrid } from "@mui/x-data-grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import countriesJSON from "../../utils/countryList/countries.json";

const today = dayjs();

const NewTransaction = (props) => {
  const NEW_TRANSACTION_URL = "/transactions";
  const axiosPrivate = useAxiosPrivate();

  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

  const {
    handleClose,
    stores,
    storeProviders,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
  } = props;

  const countries = [
    ...countriesJSON.africa,
    ...countriesJSON.asia,
    ...countriesJSON.europe,
    ...countriesJSON["latin-america"],
  ];

  const paymentMethods = [
    { id: 1, name: "Cash" },
    { id: 2, name: "Link Remoto" },
    { id: 3, name: "Cartão de Débito" },
    { id: 4, name: "Outro" },
  ];

  const columns = [
    {
      field: "bankName",
      headerName: "Nome do Banco",
      flex: 1,
    },
    {
      field: "bankBranch",
      headerName: "Agência",
      flex: 1,
    },
    {
      field: "bankAccount",
      headerName: "Conta",
      flex: 1,
    },
    {
      field: "pixKeyType",
      headerName: "Tipo de Chave PIX",
      flex: 1,
    },
    {
      field: "pixKey",
      headerName: "Chave PIX",
      headerName: "Chave Pix",
      flex: 1,
    },
  ];
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [store, setStore] = useState(stores.length === 1 ? stores[0] : null);
  const [client, setClient] = useState(null);
  const [clientInfo, setClientInfo] = useState(null);
  const [recipient, setRecipient] = useState(null);
  const [recipientInfo, setRecipientInfo] = useState(null);
  const [amount, setAmount] = useState(null);
  const [floatAmount, setFloatAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [fee, setFee] = useState(null);
  const [profit, setProfit] = useState(null);
  const [provider, setProvider] = useState(null);
  const [quotationProvider, setQuotationProvider] = useState(null);
  const [quotationClient, setQuotationClient] = useState(null);
  const [spread, setSpread] = useState(null);
  const [dateTransaction, setDateTransaction] = useState(today);
  const [destinyCountry, setDestinyCountry] = useState(
    countries.length === 1 ? countries[0] : null
  );

  const [selectedFile, setSelectedFile] = useState(null);

  const [clientRecipients, setClientRecipients] = useState([]);

  const [openNewClientDialog, setOpenNewClientDialog] = useState(false);
  const [openEditClientDialog, setOpenEditClientDialog] = useState(false);
  const [openNewRecipientDialog, setOpenNewRecipientDialog] = useState(false);
  const [openEditRecipientDialog, setOpenEditRecipientDialog] = useState(false);
  const [openProviderLimitDialog, setOpenProviderLimitDialog] = useState(false);

  const handleCloseProviderLimitDialog = () => {
    setOpenProviderLimitDialog(false);
  };

  const [clients, setClients] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [bankOptions, setBankOptions] = useState([]);
  const [recipientAccount, setRecipientAccount] = useState({});
  const [clientTransactionsTotal, setClientTransactionsTotal] = useState([]);
  const [clientTransactionsByProvider, setClientTransactionsByProvider] =
    useState([]);

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await axiosPrivate.get("/clients");
        setClients(response.data);
        setClient(null);
      } catch (err) {}
    };

    getClients();
  }, [openEditClientDialog, openNewClientDialog]);

  const getRecipients = async (client) => {
    try {
      const response = await axiosPrivate.get(
        `/recipients/clients/${client.id}`
      );
      setClientRecipients(response.data);
    } catch (err) {}
  };

  const getTransactions = async (client) => {
    try {
      const response = await axiosPrivate.get(
        `/transactions/client/${client.id}`
      );
      setClientTransactionsByProvider(summarizeTransactions(response.data));
      setClientTransactionsTotal(sumTransactions(response.data));
    } catch (err) {
      console.log(err);
    }
  };

  const summarizeTransactions = (transactions) => {
    var result = [];
    if (transactions.length === 0) {
      return result;
    } else {
      transactions.reduce(function (res, value) {
        if (!res[value.storeProvider.providerId]) {
          res[value.storeProvider.providerId] = {
            providerId: value.storeProvider.providerId,
            providerName: value.storeProvider.Provider.name,
            total: 0,
            last1Day: 0,
            last7Days: 0,
            last30Days: 0,
            lastYear: 0,
          };
          result.push(res[value.storeProvider.providerId]);
        }
        res[value.storeProvider.providerId].total += value.amount;
        if (
          dayjs(value.dateTransaction).format("YYYY-MM-DD") ===
          today.format("YYYY-MM-DD")
        ) {
          res[value.storeProvider.providerId].last1Day += value.amount;
        }
        if (dayjs(value.dateTransaction) >= today.subtract(7, "days")) {
          res[value.storeProvider.providerId].last7Days += value.amount;
        }
        if (dayjs(value.dateTransaction) >= today.subtract(30, "days")) {
          res[value.storeProvider.providerId].last30Days += value.amount;
        }
        if (dayjs(value.dateTransaction) >= today.subtract(365, "days")) {
          res[value.storeProvider.providerId].lastYear += value.amount;
        }
        return res;
      });
      return result;
    }
  };

  const sumTransactions = (transactions) => {
    var result = [];
    transactions.reduce(function (res, value) {
      if (!res["total"]) {
        res["total"] = {
          name: "Total",
          total: 0,
          last7Days: 0,
          last30Days: 0,
          lastYear: 0,
        };
        result.push(res["total"]);
      }
      res["total"].total += value.amount;
      if (dayjs(value.dateTransaction) >= today.subtract(7, "days")) {
        res["total"].last7Days += value.amount;
      }
      if (dayjs(value.dateTransaction) >= today.subtract(30, "days")) {
        res["total"].last30Days += value.amount;
      }
      if (dayjs(value.dateTransaction) >= today.subtract(365, "days")) {
        res["total"].lastYear += value.amount;
      }
      return res;
    });
    return result;
  };

  const getBankInformations = async (recipient) => {
    if (recipient) {
      try {
        const response = await axiosPrivate.get(
          `/bankInformation/recipient/${recipient.id}`
        );
        setBankOptions(response.data);
      } catch (err) {
        //console.log(err);
      }
    }
  };

  const handleAccountSelection = (accountId) => {
    if (accountId) {
      const bankAccount = bankOptions.filter((bank) => bank.id === accountId);
      setRecipientAccount(bankAccount[0]);
    } else {
      setRecipientAccount(null);
    }
  };

  const handleCloseInternalClientDialog = () => {
    setOpenNewClientDialog(false);
    setOpenEditClientDialog(false);
  };

  const handleCloseInternalRecipientDialog = () => {
    setOpenNewRecipientDialog(false);
    setOpenEditRecipientDialog(false);
    getRecipients(client);
    setRecipient(null);
  };

  const formData = new FormData();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if button enabled with JS hack

    if (
      !store ||
      !client ||
      !recipient ||
      !amount ||
      !provider ||
      !dateTransaction ||
      !profit
    ) {
      setAlertMessage("Verfifique os campos obrigatórios");
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    formData.append("storeId", store.id);
    formData.append("clientId", client.id);
    formData.append("destinyCountry", destinyCountry.name);
    formData.append("recipientId", recipient.id);
    formData.append("storeProviderId", provider.id);
    formData.append("amount", floatAmount);
    formData.append("paymentMethod", paymentMethod.name);
    formData.append("fee", fee);
    formData.append("profit", profit);
    if (quotationClient > 0) {
      formData.append("quotationClient", quotationClient);
    } else {
      formData.append("quotationClient", 0);
    }
    if (quotationProvider > 0) {
      formData.append("quotationProvider", quotationProvider);
    } else {
      formData.append("quotationProvider", 0);
    }
    formData.append("dateTransaction", dateTransaction);
    if (recipientAccount) {
      formData.append("bankName", recipientAccount.bankName);
      formData.append("bankAccount", recipientAccount.bankAccount);
      formData.append("bankAgency", recipientAccount.bankAgency);
      formData.append("keyPix", recipientAccount.keyPix);
      formData.append("typePix", recipientAccount.typePix);
    }
    if (selectedFile) {
      formData.append("receipt", selectedFile);
    }
    try {
      const response = await axiosPrivate.postForm(
        NEW_TRANSACTION_URL,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          credentials: "include",
          withCredentials: true,
        }
      );
      if (response.status === 201) {
        setAlertMessage(`Remessa registrada!`);
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message || "Erro");
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Falha no Registro da Remessa");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  const handleProfitSelect = (value) => {
    setProfit(value);
  };

  const handleFeeSelection = (value) => {
    setFee(value);

    if (
      floatAmount - value <= provider.providerFeeLowerTreshold &&
      quotationClient > 0 &&
      quotationProvider > 0
    ) {
      setProfit(
        ((quotationProvider - quotationClient) * (floatAmount - value)) /
          quotationProvider +
          (value - provider.providerFeeLower)
      );
    } else {
      setProfit(
        ((quotationProvider - quotationClient) * (floatAmount - value)) /
          quotationProvider +
          (value - provider.providerFee)
      );
    }
  };

  const handleQuotationProvider = (value) => {
    setQuotationProvider(value);
    setSpread(0);
    setProfit(0);
  };

  const handleQuotationClient = (value) => {
    setQuotationClient(value);
    if ((quotationProvider > 0) & (value > 0)) {
      setSpread(quotationProvider - value);
      if (floatAmount - fee <= provider.providerFeeLowerTreshold) {
        setProfit(
          ((quotationProvider - value) * (floatAmount - fee)) /
            quotationProvider +
            (fee - provider.providerFeeLower)
        );
      } else {
        setProfit(
          ((quotationProvider - value) * (floatAmount - fee)) /
            quotationProvider +
            (fee - provider.providerFee)
        );
      }
    } else {
      setSpread(0);
      setProfit(0);
    }
  };

  const handleSpreadSelect = (value) => {
    setSpread(value);
    if (
      quotationProvider > 0 &&
      (!quotationClient || quotationClient == 0) &&
      (!profit || profit == 0)
    ) {
      setQuotationClient(quotationProvider - value);
      if (floatAmount - fee <= provider.providerFeeLowerTreshold) {
        setProfit(
          (value * (floatAmount - fee)) / quotationProvider +
            (fee - provider.providerFeeLower)
        );
      } else {
        setProfit(
          (value * (floatAmount - fee)) / quotationProvider +
            (fee - provider.providerFee)
        );
      }
    }
  };

  const handleClientSelect = (client) => {
    setClient(client);
    setClientInfo(client);
    getRecipients(client);
    setRecipient(null);
    getTransactions(client);
  };

  const handleRecipientSelect = (recipient) => {
    getBankInformations(recipient);
    setRecipient(recipient);
    setRecipientInfo(recipient);
    setRecipientAccount(null);
  };

  const handleProviderSelect = (provider) => {
    setProvider(provider);

    if (
      provider.feeLowerOption &
      (amount - provider.fixedFeeLower <= provider.providerFeeLowerTreshold)
    ) {
      setFee(provider.fixedFeeLower);
    } else {
      setFee(provider.fixedFee);
    }
    setQuotationProvider(provider.quotationProvider);

    handleQuotationClient(0);

    const valueSent = clientTransactionsByProvider.filter(
      (prov) => prov.providerName === provider.Provider.name
    )[0];

    if (valueSent) {
      if (
        valueSent.last1Day + floatAmount >= provider.customDailyLimit ||
        valueSent.last30Days + floatAmount >= provider.customMonthlyLimit ||
        valueSent.lastYear + floatAmount >= provider.customAnnualLimit
      ) {
        setOpenProviderLimitDialog(true);
      } else {
      }
    } else {
      if (
        floatAmount >= provider.customDailyLimit ||
        floatAmount >= provider.customMonthlyLimit ||
        floatAmount >= provider.customAnnualLimit
      ) {
        setOpenProviderLimitDialog(true);
      } else {
      }
    }
  };

  const handleAmountSelect = (newValue) => {
    if ((quotationClient > 0) & (quotationProvider > 0)) {
      setSpread(quotationProvider - quotationClient);
    }

    if (provider) {
      if (
        provider.feeLowerOption &
        (newValue - provider.fixedFeeLower <= provider.providerFeeLowerTreshold)
      ) {
        setFee(provider.fixedFeeLower);
        setProfit(
          ((quotationProvider - quotationClient) *
            (newValue - provider.fixedFeeLower)) /
            quotationProvider +
            (provider.fixedFeeLower - provider.providerFeeLower)
        );
      } else {
        setFee(provider.fixedFee);
        setProfit(
          ((quotationProvider - quotationClient) *
            (newValue - provider.fixedFee)) /
            quotationProvider +
            (provider.fixedFee - provider.providerFee)
        );
      }
    }

    if (!newValue) {
      setProfit(0);
    }
  };

  return (
    <Box>
      <Header
        title="NOVA REMESSA"
        subtitle="Cadastre uma nova Remessa"
        button={false}
        color="#9f1853"
      />

      <Box
        borderRadius="10px"
        padding="20px"
        backgroundColor={colors.primary[400]}
        display="grid"
        gap="15px"
        gridTemplateColumns="repeat(4, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 4" },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 4" }}
        >
          Loja
        </Typography>
        <Autocomplete
          fullWidth
          required
          value={store}
          defaultValue={[stores[0]]}
          disableClearable
          variant="outlined"
          id="country"
          options={stores}
          getOptionLabel={(option) => option.name}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          onChange={(_, newValue) => {
            setStore(newValue);
            setProvider(null);
          }}
          renderInput={(params) => (
            <TextField {...params} size="small" label="Loja" />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        <Autocomplete
          fullWidth
          required
          value={destinyCountry}
          disableClearable
          variant="outlined"
          id="country"
          options={countries}
          getOptionLabel={(option) => option.country}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          onChange={(_, newValue) => setDestinyCountry(newValue)}
          renderInput={(params) => (
            <TextField {...params} size="small" label="Pais de Destino" />
          )}
          isOptionEqualToValue={(option, value) =>
            option.country_code === value.country_code
          }
        />
        <Box sx={{ gridColumn: "span 2" }}></Box>
      </Box>
      {store && (
        <Box
          borderRadius="10px"
          marginTop="20px"
          padding="20px"
          backgroundColor={colors.primary[400]}
          display="grid"
          gap="15px"
          gridTemplateColumns="repeat(4, 1fr)"
          sx={{
            "& > div": { gridColumns: "span 4" },
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            gridColumn="span 4"
          >
            <Typography variant="h4" fontWeight="bold">
              Cliente
            </Typography>
            <Box display="flex" gap={"10px"}>
              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.primary,
                  width: "100px",
                  ...(client && { width: "50px" }),
                }}
                id="new-client"
                onClick={setOpenNewClientDialog}
              >
                <AddCircleOutlinedIcon
                  sx={{ mr: "10px", ...(client && { mr: "0px" }) }}
                />
                {!client && <Typography fontWeight="bold">Novo</Typography>}
              </Button>

              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.primary,
                  width: "50px",
                  ...(client && { width: "100px" }),
                }}
                id="edit-client"
                onClick={setOpenEditClientDialog}
                disabled={!client}
              >
                <ModeEditOutlineOutlinedIcon
                  sx={{ mr: "10px", ...(!client && { mr: "0px" }) }}
                />
                {client && <Typography fontWeight="bold">Editar</Typography>}
              </Button>
            </Box>
          </Box>

          <Box sx={{ gridColumn: "span 4" }} />

          <Autocomplete
            fullWidth
            size="small"
            value={client}
            onChange={(event, newCliente) => {
              handleClientSelect(newCliente);
            }}
            disableClearable
            variant="outlined"
            id="nome-cliente"
            options={clients}
            getOptionLabel={(option) =>
              `${option.name}   ${option.phoneNumber}`
            }
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            renderInput={(params) => (
              <TextField {...params} label="Nome do Cliente" />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          {client && (
            <>
              <TextField
                size="small"
                fullWidth
                value={client.phoneNumber}
                variant="outlined"
                type="text"
                label="Telefone"
                //name="Telefone"
                sx={{
                  gridColumn: isNonMobile ? "span 1" : "span 2",
                }}
                inputProps={{ readOnly: true }}
              />
              <TextField
                size="small"
                fullWidth
                value={client.city}
                variant="outlined"
                type="text"
                label="Cidade"
                //name="Telefone"
                sx={{
                  backgroundColor: colors.grey[900],
                  gridColumn: isNonMobile ? "span 1" : "span 2",
                }}
                inputProps={{ readOnly: true }}
              />
            </>
          )}

          {client && (
            <>
              <Box
                marginTop="20px"
                sx={{ gridColumn: "span 4" }}
                display="flex"
                justifyContent="space-around"
              >
                <Typography fontWeight="bold">Resumo das Remessas</Typography>
              </Box>

              <Box
                display="flex"
                justifyContent="space-around"
                gap="20px"
                sx={{ gridColumn: "span 4" }}
              >
                {clientTransactionsByProvider.length > 0 ? (
                  <TableContainer
                    component={Paper}
                    sx={{ gridColumn: "span 8", maxHeight: 200 }}
                  >
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            <b>Provedora</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>7 Dias</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>30 Dias</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>365 Dias</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>Total</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {clientTransactionsByProvider.map((row) => (
                          <TableRow
                            key={row.providerId}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>{row.providerName}</TableCell>
                            <TableCell>{`USD ${USDollar.format(
                              row.last7Days
                            )}`}</TableCell>
                            <TableCell>{`USD ${USDollar.format(
                              row.last30Days
                            )}`}</TableCell>
                            <TableCell>{`USD ${USDollar.format(
                              row.lastYear
                            )}`}</TableCell>
                            <TableCell>{`USD ${USDollar.format(
                              row.total
                            )}`}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            <b>TOTAL</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>{`USD ${USDollar.format(
                              clientTransactionsTotal[0].last7Days
                            )}`}</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>{`USD ${USDollar.format(
                              clientTransactionsTotal[0].last30Days
                            )}`}</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>{`USD ${USDollar.format(
                              clientTransactionsTotal[0].lastYear
                            )}`}</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>{`USD ${USDollar.format(
                              clientTransactionsTotal[0].total
                            )}`}</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography
                    sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
                  >
                    Não existem Remessas para o cliente
                  </Typography>
                )}
              </Box>
            </>
          )}
        </Box>
      )}
      {/* PARTE DO BENEFICIÁRO */}
      {client && (
        <Box
          borderRadius="10px"
          padding="20px"
          marginTop="20px"
          marginBottom="20px"
          backgroundColor={colors.primary[400]}
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          gap="15px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            gridColumn="span 4"
          >
            <Typography variant="h4" fontWeight="bold">
              Beneficiário
            </Typography>
            <Box display="flex" gap={"10px"}>
              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.primary,
                  width: "100px",
                  ...(recipient && { width: "50px" }),
                }}
                id="new-recipient"
                onClick={setOpenNewRecipientDialog}
              >
                <AddCircleOutlinedIcon
                  sx={{ mr: "10px", ...(recipient && { mr: "0px" }) }}
                />
                {!recipient && <Typography fontWeight="bold">Novo</Typography>}
              </Button>

              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.primary,
                  width: "50px",
                  ...(client && { width: "100px" }),
                }}
                id="edit-client"
                onClick={setOpenEditRecipientDialog}
                disabled={!recipient}
              >
                <ModeEditOutlineOutlinedIcon
                  sx={{ mr: "10px", ...(!recipient && { mr: "0px" }) }}
                />
                {recipient && <Typography fontWeight="bold">Editar</Typography>}
              </Button>
            </Box>
          </Box>

          <Box sx={{ gridColumn: "span 4" }} visibility="hidden" />
          <Autocomplete
            value={recipient}
            onChange={(_, newRecipient) => {
              handleRecipientSelect(newRecipient);
            }}
            disableClearable
            variant="outlined"
            id="recipient-name"
            options={clientRecipients}
            getOptionLabel={(option) => `${option.name}`}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 2" }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Nome do Beneficiário"
              />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          {recipient && (
            <>
              <TextField
                size="small"
                fullWidth
                value={recipient.identificationDocument}
                variant="outlined"
                type="text"
                label="Documento"
                sx={{
                  backgroundColor: colors.grey[900],
                  gridColumn: isNonMobile ? "span 1" : "span 2",
                }}
                inputProps={{ readOnly: true }}
              />
              {bankOptions.length > 0 && (
                <Box
                  sx={{
                    gridColumn: "span 4",
                    "& .MuiDataGrid-root": {
                      border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "none",
                    },
                    "& .name-column--cell": {
                      color: "#0c1c3c",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: "#E35A01",
                      borderBottom: "none",
                      color: "white",
                    },

                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                      {
                        display: "none",
                      },

                    "& .MuiDataGrid-virtualScroller": {
                      backgroundColor: colors.primary[400],
                    },
                  }}
                >
                  <DataGrid
                    getRowId={(row) => row.id}
                    columns={columns}
                    rows={bankOptions}
                    rowThreshold={0}
                    disableColumnMenu
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    disableMultipleRowSelection={true}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      handleAccountSelection(newRowSelectionModel[0]);
                    }}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      )}

      {/* PARTE DA REMESSA */}

      {recipient && (
        <Box
          borderRadius="10px"
          padding="20px"
          marginTop="20px"
          backgroundColor={colors.primary[400]}
          display="grid"
          gap="15px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          <Box sx={{ gridColumn: "span 4" }}>
            <Typography variant="h4" fontWeight="bold">
              Remessa
            </Typography>
          </Box>

          <DatePicker
            disableFuture
            label="Data da Remessa (AAAA-MM-DD)"
            value={dateTransaction}
            defaultValue={today.date()}
            timezone="system"
            onChange={(newValue) => setDateTransaction(dayjs(newValue))}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            format="YYYY-MM-DD"
          />

          <NumericFormat
            decimalScale={2}
            thousandSeparator
            value={amount}
            customInput={TextField}
            required
            id="amount"
            variant="outlined"
            type="text"
            label="Valor da Remessa com Taxas"
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            onValueChange={(e) => {
              setAmount(USDollar.format(e.value));
              setFloatAmount(e.floatValue);
            }}
            onBlur={(e) => handleAmountSelect(floatAmount)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />

          <Autocomplete
            fullWidth
            disabled={!amount || amount <= 0}
            value={paymentMethod}
            disableClearable
            variant="outlined"
            id="payment-method"
            options={paymentMethods}
            getOptionLabel={(option) => option.name}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            onChange={(_, newValue) => setPaymentMethod(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Forma de Pagamento" />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />

          <Autocomplete
            fullWidth
            required
            disabled={!amount || amount <= 0}
            value={provider}
            disableClearable
            variant="outlined"
            id="provider"
            options={storeProviders
              .filter((provider) => provider.storeId === store.id)
              .filter((provider) => provider.active)}
            getOptionLabel={(option) => option.Provider.name}
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
            onChange={(_, newValue) => handleProviderSelect(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Provedora" />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <NumericFormat
            decimalScale={2}
            fixedDecimalScale
            disabled={!amount || amount <= 0}
            thousandSeparator
            value={fee}
            customInput={TextField}
            required
            id="fee"
            variant="outlined"
            type="text"
            label="Taxa"
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
            //onValueChange={(e) => setFee(e.value)}
            onValueChange={(e) => handleFeeSelection(e.floatValue)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />

          <NumericFormat
            disabled={amount <= 0 || !fee}
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator
            value={quotationProvider}
            customInput={TextField}
            id="quotation-provider"
            variant="outlined"
            type="text"
            label="Cotação da Provedora"
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
            onValueChange={(e) => handleQuotationProvider(e.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {destinyCountry.iso_code}{" "}
                </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          <Box
            display="grid"
            rowGap={isNonMobile ? "0px" : "15px"}
            columnGap="15px"
            gridTemplateColumns="repeat(3, 1)"
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          >
            <Box sx={{ gridColumn: isNonMobile ? "span 3" : "span 4" }} />
            <NumericFormat
              disabled={
                amount <= 0 || !quotationProvider || quotationProvider == 0
              }
              decimalScale={2}
              backgroundColor={"blue"}
              thousandSeparator
              value={quotationClient}
              customInput={TextField}
              id="quotation-client"
              variant="standard"
              type="text"
              label="Cotação do Cliente"
              sx={{
                gridColumn: isNonMobile ? "span 1" : "span 4",
                marginLeft: isNonMobile ? "20px" : "Opx",
              }}
              onValueChange={(e) => handleQuotationClient(e.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {destinyCountry.iso_code}{" "}
                  </InputAdornment>
                ),
                sx: { "& input": { textAlign: "right" } },
              }}
            />
            {isNonMobile && (
              <Typography
                align="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                sx={{ gridColumn: isNonMobile ? "span 1" : "span 1" }}
              >
                ou{" "}
              </Typography>
            )}
            <NumericFormat
              disabled={quotationClient > 0 || !amount || amount <= 0}
              decimalScale={2}
              thousandSeparator
              value={spread}
              customInput={TextField}
              required
              id="spread"
              variant="standard"
              type="text"
              label="Spread"
              sx={{
                gridColumn: isNonMobile ? "span 1" : "span 4",
                marginRight: isNonMobile ? "20px" : "0px",
              }}
              //onChange={(event) => setSpread(event.target.value)}
              onBlur={(event) => handleSpreadSelect(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {destinyCountry.iso_code}
                  </InputAdornment>
                ),
                sx: { "& input": { textAlign: "right" } },
              }}
            />
          </Box>
          <NumericFormat
            decimalScale={2}
            disabled={amount <= 0}
            thousandSeparator
            value={profit}
            customInput={TextField}
            required
            id="profit"
            variant="outlined"
            type="text"
            label="Lucro"
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
            onValueChange={(e) => handleProfitSelect(e.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          <Button
            variant="contained"
            component="label"
            startIcon={<CloudUploadOutlinedIcon />}
          >
            ANEXAR RECIBO
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          {selectedFile && (
            <Typography>
              Arquivo: <b>{selectedFile.name}</b>
            </Typography>
          )}
        </Box>
      )}
      <Box height="20px">
        <Box
          borderRadius="10px"
          padding="10px"
          marginTop="20px"
          marginBottom="20px"
          backgroundColor={colors.primary[400]}
          display="grid"
          gap="5px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          <Box sx={{ gridColumn: "span 4" }} />
          <Box sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }} />
          <Button
            type="submit"
            disabled={
              !store ||
              !client ||
              !recipient ||
              !amount ||
              !provider ||
              !dateTransaction ||
              !profit
            }
            color="secondary"
            variant="contained"
            height="100%"
            onClick={handleSubmit}
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
          >
            <Typography fontWeight="bold">Registrar Remessa</Typography>
          </Button>
          <ColorButton
            variant="contained"
            height="100%"
            onClick={handleClose}
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
          >
            Cancelar
          </ColorButton>
        </Box>
      </Box>
      <Box />

      <ClientDialog
        openNewClientDialog={openNewClientDialog}
        handleClose={handleCloseInternalClientDialog}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />
      <EditClientDialog
        openEditClientDialog={openEditClientDialog}
        handleClose={handleCloseInternalClientDialog}
        clientInfo={clientInfo}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />
      <RecipientDialog
        openNewRecipientDialog={openNewRecipientDialog}
        handleClose={handleCloseInternalRecipientDialog}
        clients={clients}
        recipients={recipients}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
        clientInfo={client}
      />

      <EditRecipientDialog
        openEditRecipientDialog={openEditRecipientDialog}
        handleClose={handleCloseInternalRecipientDialog}
        recipientInfo={recipientInfo}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />
      <ProviderLimitDialog
        open={openProviderLimitDialog}
        handleClose={handleCloseProviderLimitDialog}
      />
    </Box>
  );
};

export default NewTransaction;
