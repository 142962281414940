import { Box } from "@mui/material";
import Dropzone from "react-dropzone";
import { useState } from "react";

export function Container() {

  const [uploadedFile, setUploadedFile] = useState([])

  const handleUpload = (file) => {
    if(['xls', 'xlsx'].includes(file[0].name.split('.').pop())) {
      setUploadedFile(file[0])
    }
  }

 

  return (
    <>
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "purple",
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "400px",
          margin: "30px",
          borderRadius: "4px",
          padding: "20px",
          backgroundColor: "white",
        }}
      >
        <Dropzone maxFiles={1} onDropAccepted={handleUpload}>
        {({getRootProps, getInputProps}) => (
          <Box {...getRootProps()}  sx={{
            border:'1px dashed',
            borderRadius: '4px',
            cursor: 'pointer',
            transition:'height 0.2s ease',
            borderColor: "#black",
            height: 50
            }} >
              
              <input {...getInputProps()} />
              Arraste o Arquivi do Excel aqui!

          </Box>
        )
          
        }
        </Dropzone>
        <Box>
          {uploadedFile.map(file => (
            <li key={file.name}>{file.name}</li>
          ))}
        </Box>
      </Box>
    </Box>
  </>
  );
}