import { Box, Typography, useTheme, useMediaQuery, Grid, Switch} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { tokens } from "../../theme";
import { storeContext } from "../../context/storeContext";
import { useTranslation } from "react-i18next";

import FormControl from '@mui/material/FormControl';
import Header from "../../components/Header"
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import useAxiosPrivate from "../../api/useAxiosPrivate";
import SelectStore from "../../components/SelectStore";
import LineChart from "../../components/LineChart";
import PieChart from "../../components/PieChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import dayjs from "dayjs";
const today = dayjs();


const Dashboard = () => {
    const axiosPrivate = useAxiosPrivate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:1200px)");
    const {t} = useTranslation()

    let USDollar = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2  })
    let percentFormat = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2  })

    const [storesList, setStoresList] = useContext(storeContext)
    const [stores, setStores] = useState([])
    const [filteredTransactions, setFilteredTransactions] = useState([])

    const [transactions, setTransactions] = useState([]);
    const [storeProviders, setStoreProviders] = useState([])
    const [pieDataAmount, setPieDataAmount] = useState([])
    const [pieDataQuantity, setPieDataQuantity] = useState([])
    const [barData, setBarData] = useState([])
    const [lineDataAmount, setLineDataAmount] = useState([])
    const [lineDataProfit, setLineDataProfit] = useState([])
    const [keys, setKeys] = useState([])
    const [selectProfit, setSelectProfit] = useState(true)

    useEffect(() => {
        const getTransactions = async () => {
          try {
            const response = await axiosPrivate.get("/transactions/storeChain30");
            setTransactions(response.data);
            setBarData(pivotBy(response.data))
          } catch (err) {}
        };
        getTransactions();
        

      }, []);

    const pivotBy = (data) => {
        let calendar = [];
        let barAux = []
        let providers = []
        let providersColors = []
        let i = today;
        let newData = data.filter((transaction) => dayjs(transaction.dateTransaction) >= today.subtract(7, 'days') )
  
        while (i > today.subtract(7, 'days')) {
            calendar.push(i.format('YYYY-MM-DD'))
            i = i.subtract(1, 'days')
        }
        calendar.reverse()

        newData.forEach((transaction) => {
            if(!providers.includes(transaction.storeProvider.Provider.name)) {
                providers.push(transaction.storeProvider.Provider.name)
                providersColors.push(transaction.storeProvider.Provider.providerColor)
            }})

        calendar.forEach((day) => {
            barAux.push(
                {'dateTransaction': day}
            )
            providers.forEach((provider) => {
                barAux.forEach((obj) => {
                    if(!obj[provider]) {
                        obj[provider] = 0
                        obj[`${provider}_color`] = providersColors[providers.indexOf(provider)]
                    }
                })
            })
        })


        newData.forEach((transaction) => {
            barAux.forEach((day) => {     
                if (day.dateTransaction === transaction.dateTransaction) {
                    day[transaction.storeProvider.Provider.name] = day[transaction.storeProvider.Provider.name] += transaction.amount
                    day[`${transaction.storeProvider.Provider.name}_color`] = transaction.storeProvider.Provider.providerColor
                }
            })
        })
        return barAux;    
    }

    const pivotLine = (data, providers) => {
        let uniqueKeys = [];
        let lineAux = []
        let i = today;

        providers = providersToKeys(providers)
      
        let transactions2 = data.filter((transaction) => transaction.dateTransaction >=today.subtract(30, 'days').format('YYYY-MM-DD'))

        transactions2.forEach((transaction) => {
            const found = (lineAux.some(el => el.id === transaction.storeProvider.Provider.name))
            if (!found){
                lineAux.push(
                    {'id': transaction.storeProvider.Provider.name,
                    'color': transaction.storeProvider.Provider.providerColor,
                    'data': []}
                )    
            }    
        }
        )



        while (i > today.subtract(30, 'days')) {
            uniqueKeys.push(i.format('YYYY-MM-DD'))
            i = i.subtract(1, 'days')
        }
        uniqueKeys.reverse()


        lineAux.forEach((obj) => {
            uniqueKeys.forEach((day) => {
                obj.data.push(
                    {'x': day, 'y': 0}
                );            
            })
        })
        

   

        transactions2.forEach((transaction) => {
            lineAux.forEach((obj) => {
                if(obj.id === transaction.storeProvider.Provider.name){
                    obj.data.forEach((day) => {
                        if(day.x >= dayjs(transaction.dateTransaction).format('YYYY-MM-DD')){
                            day.y += transaction.amount
                        }
                    })
                }
            })
        
        })


        return lineAux

    }

    const pivotLine2 = (data, providers) => {
        let uniqueKeys = [];
        let lineAux = []
        let i = today;


        providers = providersToKeys(providers)
      
        let transactions2 = data.filter((transaction) => transaction.dateTransaction >=today.subtract(30, 'days').format('YYYY-MM-DD'))

        transactions2.forEach((transaction) => {
            const found = (lineAux.some(el => el.id === transaction.storeProvider.Provider.name))
            if (!found){
                lineAux.push(
                    {'id': transaction.storeProvider.Provider.name,
                    'color': transaction.storeProvider.Provider.providerColor,
                    'data': []}
                )    
            }    
        }
        )



        while (i > today.subtract(30, 'days')) {
            uniqueKeys.push(i.format('YYYY-MM-DD'))
            i = i.subtract(1, 'days')
        }
        uniqueKeys.reverse()


        lineAux.forEach((obj) => {
            uniqueKeys.forEach((day) => {
                obj.data.push(
                    {'x': day, 'y': 0}
                );            
            })
        })
        

   

        transactions2.forEach((transaction) => {
            lineAux.forEach((obj) => {
                if(obj.id === transaction.storeProvider.Provider.name){
                    obj.data.forEach((day) => {
                        if(day.x >= dayjs(transaction.dateTransaction).format('YYYY-MM-DD')){
                            day.y += transaction.profit
                        }
                    })
                }
            })
        
        })
        return lineAux
    }

    

    const pivotPieAmount = (data) => {
        let auxSlicesAmount = []
        let providersName = []
        let providers = []

        data = data.filter((transaction) => {
            return dayjs(transaction.dateTransaction).format('YYYY-MM-DD') > today.subtract(7, 'days').format('YYYY-MM-DD')
        })

        data.forEach((transaction) => {
            if(!providersName.includes(transaction.storeProvider.Provider.name)) {
                providersName.push(transaction.storeProvider.Provider.name)
                providers.push(transaction.storeProvider.Provider)
            }})


        providers.forEach((provider) => {
            auxSlicesAmount.push({
                'providerId': provider.name,
                'label': provider.name,
                'value': 0,
                'color': provider.providerColor
            });
        })

        auxSlicesAmount.forEach((slice) => {
            data.forEach((transaction) => {
                if(slice.providerId === transaction.storeProvider.Provider.name){
                    slice.value += transaction.amount
                }
            })
        })

        return auxSlicesAmount.filter((slice) => slice.value > 0)
        
    }

    const pivotPieQuantity = (data) => {
        let auxSlicesQuantity = []
        let providersName = []
        let providers = []

        data = data.filter((transaction) => {
            return dayjs(transaction.dateTransaction).format('YYYY-MM-DD') > today.subtract(7, 'days').format('YYYY-MM-DD')
        })
   
        data.forEach((transaction) => {
            if(!providersName.includes(transaction.storeProvider.Provider.name)) {
                providersName.push(transaction.storeProvider.Provider.name)
                providers.push(transaction.storeProvider.Provider)
            }})


        providers.forEach((provider) => {
            auxSlicesQuantity.push({
                'providerId': provider.name,
                'label': provider.name,
                'value': 0,
                'color': provider.providerColor
            });

        })
        auxSlicesQuantity.forEach((slice) => {
            data.forEach((transaction) => {
                if(slice.providerId == transaction.storeProvider.Provider.name){
                    slice.value += 1
                }
            })
        })

        return auxSlicesQuantity.filter((slice) => slice.value > 0);
    }



    const providersToKeys = (providers) => {
        let unique = [];
        let aux_unique = []


        providers.forEach(element => {
        if (!aux_unique.includes(element.Provider.name)) {
            unique.push({"name": element.Provider.name, "color": element.Provider.providerColor});
            aux_unique.push(element.Provider.name);
        }
    });

    return unique;
 
}

const providersToKeysBarPlot = (providers) => {
    let unique = [];
    let aux_unique = []

    if (storesList.length > 0) {
        providers = providers.filter((provider) => {
            return storesList.some((obj) => {
                return obj.id === provider.storeId       
            })
        })
    }


    providers.forEach(element => {
    if (!aux_unique.includes(element.Provider.name)) {
        unique.push({"name": element.Provider.name, "color": element.Provider.providerColor});
        aux_unique.push(element.Provider.name);
    }
    });


    return unique;
    }

      useEffect(()=> {
        const getStores = async () => {
            try {
                const response = await axiosPrivate.get("/stores");
                setStores(response.data);
            } catch (err) {}
        }
        getStores()
      }, [])



      useEffect(() => {
        const setChartsData = () => {
            let result = transactions.map(data => {
                return {...data, dateTransaction: dayjs(data.dateTransaction).format('YYYY-MM-DD')}
            })  
            
            if (storesList.length > 0) {
                result = result.filter((transaction) => {
                    return storesList.some((obj) => {
                        return obj.id === transaction.storeId       
                    })
                })
            }

            setFilteredTransactions(result)
            setBarData(pivotBy(result, storeProviders))
            setPieDataAmount(pivotPieAmount(result, storeProviders))
            setPieDataQuantity(pivotPieQuantity(result, storeProviders))
            setLineDataAmount(pivotLine(result, storeProviders))
            setLineDataProfit(pivotLine2(result, storeProviders))
        }
        setChartsData()
      }, [transactions, storeProviders, storesList])

    
      const periodTransactions = (transactions) => ({
        
        last7DaysCount: transactions
          .filter(
            (transaction) =>
              dayjs(transaction.dateTransaction) >= today.subtract(7, "days")
          ).length,

          last7DaysAmount: transactions
          .filter(
            (transaction) =>
              dayjs(transaction.dateTransaction) >= today.subtract(7, "days")
          )
          .reduce((accumulator, object) => {
            return accumulator + object.amount;
          }, 0),


        todayCount: transactions
          .filter(
            (transaction) =>
              dayjs(transaction.dateTransaction).format('YYMMDD') === today.format('YYMMDD')
          )
          .length,

          todayAmount: transactions
          .filter(
            (transaction) =>
              dayjs(transaction.dateTransaction).format('YYMMDD') === today.format('YYMMDD')
          )
          .reduce((accumulator, object) => {
            return accumulator + object.amount;
          }, 0),

        yesterdayCount: transactions
        .filter(
          (transaction) =>
            dayjs(transaction.dateTransaction).format('YYMMDD') === today.subtract(1, 'days').format('YYMMDD')
        )
        .length,

        yesterdayAmount: transactions
          .filter(
            (transaction) =>
            dayjs(transaction.dateTransaction).format('YYMMDD') === today.subtract(1, 'days').format('YYMMDD')
          )
          .reduce((accumulator, object) => {
            return accumulator + object.amount;
          }, 0),

          weekBeforeCount: transactions
          .filter(
            (transaction) =>
              dayjs(transaction.dateTransaction).format('YYMMDD') >= today.subtract(14, 'days').format('YYMMDD')
              && dayjs(transaction.dateTransaction).format('YYMMDD') < today.subtract(7, 'days').format('YYMMDD')
          )
          .length,

          weekBeforeAmount: transactions
          .filter(
            (transaction) =>
            dayjs(transaction.dateTransaction).format('YYMMDD') >= today.subtract(14, 'days').format('YYMMDD')
            && dayjs(transaction.dateTransaction).format('YYMMDD') < today.subtract(7, 'days').format('YYMMDD')
                  )
          .reduce((accumulator, object) => {
            return accumulator + object.amount;
          }, 0),

          last30DaysAmount: transactions.filter((transaction) => 
          dayjs(transaction.dateTransaction).format('YYMMDD') >= today.subtract(30, 'days').format('YYMMDD')
          )
          .reduce((accumulator, object) => {
            return accumulator + object.amount;
          }, 0),

          last30DaysProfit: transactions.filter((transaction) => 
          dayjs(transaction.dateTransaction).format('YYMMDD') >= today.subtract(30, 'days').format('YYMMDD')
          )
          .reduce((accumulator, object) => {
            return accumulator + object.profit;
          }, 0)
      });

      const [transactionsPeriod, setTransactionsPeriod] = useState({})

      useEffect(() => {
        setTransactionsPeriod(periodTransactions(filteredTransactions))
      }, [transactions, storesList, filteredTransactions])


      const compareByDate = (a, b) => {
        return dayjs(a.dateTransaction).unix() - dayjs(b.dateTransaction).unix()
      }

      transactions.sort(compareByDate).reverse();


    return (
    
        <Box m="20px" marginLeft="280px">
            <Header title={t('dashboard.header.title')} subtitle={t('dashboard.header.subtitle')} />
        {/* GRID AND CHARTS */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(8, 1fr)"
                //gridAutoRows="140px"
                gap="15px"
            >

                {/* ROW 1*/}
                <Box gridColumn={isNonMobile ? "span 8": "span 8"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    padding={2}
                    //alignItems="center"
                    //justifyContent="center"
                    sx={{ borderRadius: 2 }}>
                <SelectStore stores={stores}/>
                </Box>
                <Box 
                    gridColumn={isNonMobile ? "span 2": "span 4"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ borderRadius: 2 }}
                >
                    <StatBox
                        title={transactionsPeriod.todayCount}
                        subtitle={t('dashboard.statsBox.subtitleStatsBox1')}
                        increase={transactionsPeriod.yesterdayCount > 0 ? `${100 * (transactionsPeriod.todayCount/transactionsPeriod.yesterdayCount)}${t('dashboard.statsBox.increaseStatsBox1_option1')}` : `${t('dashboard.statsBox.increaseStatsBox1_option2')}`}
                        icon={
                            <CurrencyExchangeOutlinedIcon
                                sx={{color: "#0c1c3c", fontsize: "26px"}}
                            />
                        }
                    />
                </Box>
                <Box 
                    gridColumn={isNonMobile ? "span 2": "span 4"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ borderRadius: 2 }}

                >
                    <StatBox
                        title={`USD ${USDollar.format(transactionsPeriod.todayAmount)}`}
                        subtitle={t('dashboard.statsBox.subtitleStatsBox2')}
                        increase={transactionsPeriod.yesterdayAmount > 0 ? `${percentFormat.format(100 * (transactionsPeriod.todayAmount/transactionsPeriod.yesterdayAmount))}${t('dashboard.statsBox.increaseStatsBox2option1')}` : `${t('dashboard.statsBox.increaseStatsBox2_option2')}`}
                        icon={
                            <PaymentsOutlinedIcon
                                sx={{color: "#0c1c3c", fontsize: "26px"}}
                            />
                        }
                    />
                </Box>
                <Box 
                    gridColumn={isNonMobile ? "span 2": "span 4"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ borderRadius: 2 }}

                >
                    <StatBox
                        title={transactionsPeriod.last7DaysCount}
                        subtitle={t('dashboard.statsBox.subtitleStatsBox3')}
                        increase={transactionsPeriod.weekBeforeCount > 0 ? `${percentFormat.format(100 * (transactionsPeriod.last7DaysCount/transactionsPeriod.weekBeforeCount))}${t('dashboard.statsBox.increaseStatsBox3_option1')}` : `${t('dashboard.statsBox.increaseStatsBox3_option2')}`}
                        icon={
                            <CurrencyExchangeOutlinedIcon
                                sx={{color: "#0c1c3c", fontsize: "26px"}}
                            />
                        }
                    />
                </Box>
                <Box 
                    gridColumn={isNonMobile ? "span 2": "span 4"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ borderRadius: 2 }}

                >
                    <StatBox
                        title={`USD ${USDollar.format(transactionsPeriod.last7DaysAmount)}`}
                        subtitle={t('dashboard.statsBox.subtitleStatsBox4')}
                        increase={transactionsPeriod.weekBeforeAmount > 0 ? `${percentFormat.format(100 * (transactionsPeriod.last7DaysAmount/transactionsPeriod.weekBeforeAmount))}${t('dashboard.statsBox.increaseStatsBox4_option1')}` : `${t('dashboard.statsBox.increaseStatsBox4_option2')}`}
                        icon={
                            <PaymentsOutlinedIcon
                                sx={{color: "#0c1c3c", fontsize: "26px"}}
                            />
                        }
                    />
                </Box>
            
                {/* ROW 2 */}
                <Box
                    gridColumn={isNonMobile ? "span 6" : "span 8"}
                    gridRow="span 1"
                    backgroundColor={colors.primary[400]}
                    sx={{ borderRadius: 2 }}

                >
                    <Box
                        mt="25px"
                        p="0 30px"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box>
                            <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
                                {t('dashboard.charts.lastWeekBarChart')}
                            </Typography>
                            <Typography
                                variant="h3" fontWeight="bold" color={colors.greenAccent[500]}
                            >
                                {`USD ${USDollar.format(transactionsPeriod.last7DaysAmount)}`}
                            </Typography>
                        </Box>

                    </Box>
                    <Box  height="250px" mt="-20px">
                        {barData && (<BarChart isDashboard={true} data={barData} keys={keys} isNonMobile={isNonMobile}/>)}
                    </Box>
                </Box>


                {isNonMobile && (<Box gridColumn="span 2" gridRow="span 2" backgroundColor={colors.primary[400]} sx={{ borderRadius: 2 }}
>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            colors={colors.grey[100]}
                            p="15px"
                        >
                            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
                            {t('dashboard.charts.lastTransactions')}
                            </Typography>
                        </Box>
                        {filteredTransactions.slice(0, 5).map((transaction) => (    
                            <Box
                                key={transaction.id}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                borderBottom={`4px solid black`}
                                flexDirection='column'
                                p="15px">
                                    <Box>
                                        <Typography
                                            color={"#0c1c3c"} variant="h5" fontWeight="600" justifyContent='center'>
                                        {transaction.client.name || `${t('dashboard.charts.unknownPerson')}`}
                                        </Typography>
                                        <Typography
                                            color={colors.grey[100]} justifyContent='center' display='flex'>
                                        {transaction.recipient.name || `${t('dashboard.charts.unknownPerson')}`}
                                        </Typography>
                                    </Box>
                                    <Box color={colors.grey[100]}>{dayjs(transaction.dateTransaction).format('YYYY-MM-DD')}</Box>
                                    <Box backgroundColor={colors.greenAccent[500]} p="3px" borderRadius="4px" justifyContent="center" display={"flex"}>
                                        <Typography>{`USD ${USDollar.format(transaction.amount)}`}</Typography>
                                    </Box>
                            </Box>
                        ))}
                    </Box >)
}
                    
      

                    
                    {/* ROW 3*/}
                    <Box
                    gridColumn={isNonMobile ? "span 3" : "span 4"}
                    gridRow="span 1"
                    backgroundColor={colors.primary[400]}
                    sx={{ borderRadius: 2 }}

                >
                    <Box
                        mt="25px"
                        p="0 30px"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box>
                            <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
                                {t('dashboard.charts.lastWeekAmountPieChart')}
                            </Typography>
                            <Typography
                                variant="h3" fontWeight="bold" color={colors.greenAccent[500]}
                            >
                                {`USD ${USDollar.format(transactionsPeriod.last7DaysAmount)}`}
                            </Typography>
                        </Box>

                    </Box>
                    <Box  height="250px" mt="-20px">
                       <PieChart data={pieDataAmount} dolar={true}/>

                        
                    </Box>
                    </Box>

                    <Box
                    gridColumn={isNonMobile ? "span 3" : "span 4"}
                    gridRow="span 1"
                    backgroundColor={colors.primary[400]}
                    sx={{ borderRadius: 2 }}

                >
                    <Box
                        mt="25px"
                        p="0 30px"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box>
                            <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
                            {t('dashboard.charts.lastWeekQuantityPieChart')}
                            </Typography>
                            <Typography
                                variant="h3" fontWeight="bold" color={colors.greenAccent[500]}
                            >
                                {transactionsPeriod.last7DaysCount}
                            </Typography>
                        </Box>

                    </Box>
                    <Box  height="250px" mt="-20px">
                       <PieChart data={pieDataQuantity} dolar={false}/>

                    </Box>
                    </Box>
    
                    <Box
                    gridColumn={isNonMobile ? "span 8" : "span 8"}
                    gridRow="span 1"
                    backgroundColor={colors.primary[400]}
                    sx={{ borderRadius: 2 }}

                >
                    <Box
                        mt="25px"
                        p="0 30px"
                    >
                        <Box display='flex' justifyContent='space-between' alignContent='center'>
                            <Box >
                                <Box>
                            <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
                                {selectProfit ?  `${t('dashboard.charts.lastMonthAreaChart_option1')}` : `${t('dashboard.charts.lastMonthAreaChart_option2')}`}
                            </Typography>
                            </Box>
                            <Typography
                                variant="h3" fontWeight="bold" color={colors.greenAccent[500]}
                            >
                                {selectProfit? `USD ${USDollar.format(transactionsPeriod.last30DaysProfit)}` : `USD ${USDollar.format(transactionsPeriod.last30DaysAmount)}`}
                            </Typography>
                            </Box>
                            <Box display='flex' flexDirection='column'>
                             <FormControl sx={{ minWidth: "20%" }} >

                             <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>{t('dashboard.charts.lastMonthAreaChartPicker_option2')}</Grid>
                                <Grid item>
                                    <Switch
                                        checked={selectProfit} // relevant state for your case
                                    onChange={(e) => setSelectProfit(e.target.checked)}
                                    value={selectProfit}
                                    />
                                </Grid>
                                <Grid item>{t('dashboard.charts.lastMonthAreaChartPicker_option1')}</Grid>
                            </Grid>
  
                            </FormControl>
                            </Box>
                        </Box>

                    </Box>
                    <Box  height="250px" mt="-20px">
                        <LineChart dataProfit={lineDataProfit} dataAmount={lineDataAmount} selectProfit={selectProfit}/>

                    </Box>
                    </Box>
                    </Box>
            </Box>
    );
};

export default Dashboard;