import { useState, useEffect, useDebugValue } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import 'react-pro-sidebar/dist/css/styles.css';
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import axios from "axios";
import LogoutOutlinedIcon  from "@mui/icons-material/LoginOutlined";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import useLogout from "../../hooks/useLogout";
import Divider from '@mui/material/Divider';
import i18n from "../../utils/i18n";

import usFlag from "../../utils/countryFlags/en-us/us-circle-01-XS.png"
import brFlag from "../../utils/countryFlags/pt-br/br-circle-01-XS.png"
import spFlag from "../../utils/countryFlags/es-sp/es-circle-01-XS.png"
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";

const Item = ({ title, to, icon, selected, setSelected, target='_self' }) => {
    const theme = useTheme();

 

    return(
        <MenuItem
        active={selected === title}
        style={{color: "#0c1c3c"}}
        onClick={() => setSelected(title)}
        icon={icon}>
            <Typography>{title}</Typography>
            <Link to={to} target={target}/>
        </MenuItem>
    )    
}

const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selected, setSelected] = useState("Dashboard");
    const [dolar, setDolar] = useState({"USDBRL": {"bid": "Carregando", "create_date": "Carregando"}});
    const navigate = useNavigate();
    const logout = useLogout();
    const {t} = useTranslation()
    const changeLanguage = (language) => {
        i18n.changeLanguage(language)
    }

    const signOut = async () => {
        await logout();
        navigate('/login');
    }

    const getDolar = async () => {
        await axios("https://economia.awesomeapi.com.br/json/last/USD-BRL").then(response => setDolar(response.data))
    }

    useEffect(() => {getDolar()}, [])



    return (
        <Box height="100%" position="fixed"
         sx={{
            "& .pro-sidebar-inner": {
                background: `${colors.primary[400]} !important`
            },
            "& .pro-icon-wrapper" : {
                background: "transparent !important"
            },
            "& .pro-inner-item": {
                padding: "5px 35px 5px 20px !important"
            },
            "& .pro-inner-item:hover": {
                color: "#79acfb !important"
            },  
            "& .pro-menu-item.active": {
                color: "#0c64fb !important"
            },

         }}
        >
            <ProSidebar>
                <Menu iconShape="square">
                    {/* USER */}
                    {/*{!isCollapsed && (
                        <Box mb="25px">
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <img
                                 alt="profile"
                                 height="100px"
                                src={`../../assets/user.png`}
                                style={{ cursor: 'pointer', borderRadius: "50%"}}
                                />
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                 variant="h2"
                                 color={colors.grey[100]}
                                 fontWeight='bold'
                                 sx={{m: "10px 0 0 0 "}}
                                 >
                                    Ed Roh
                                </Typography>
                                <Typography
                                 variant="h5" color={colors.greenAccent[500]}
                                >
                                    VP Fancy Admin</Typography>
                            </Box>
                        </Box>
                    )} */}

                    {/* MENU ITEMS */}
                    <Box>
                        <Box padding="20px">
                            <Box display="flex" justifyContent="left" alignItems="center">
                                {theme.palette.mode === 'dark' ? (
                                    <img src={"../../assets/logo2.png"} height="70px" alt="logo"/>
                                ) : (
                                    <img src={"../../assets/logo.png"} height="70px" alt="logo"/>
                                )}
                            </Box>
                            <Box marginTop="20px">
                                <Typography
                                    color={"#0c1c3c"} variant="h5" fontWeight="400" textAlign="center">
                                {t('sidebar.dolar')}
                                </Typography>
                                
                            </Box>
                            <Box color={colors.primary[500]}  backgroundColor={"#b2bed1"} p="   5px"
                            borderRadius="4px">
                                <Typography textAlign="center" variant="h5" fontWeight="600">{`R$ ${parseFloat(dolar.USDBRL.bid).toFixed(2)}`}</Typography>
                            </Box>
                            <Typography textAlign="center" color={"#0c1c3c"}>{dolar.USDBRL.create_date}</Typography>

                        </Box>
                        <Divider variant="middle"/>
                        <Item
                            title={t('sidebar.dashboard')}
                            to="/"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title={t('sidebar.transactions')}
                            to="/transactions"
                            icon={<CurrencyExchangeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title={t('sidebar.clients')}
                            to="/clients"
                            icon={<PeopleAltOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        
                        <Item
                            title={t('sidebar.providers')}
                            to="/providers"
                            icon={<AccountBalanceOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title={t('sidebar.reports')}
                            to="/reports"
                            icon={<AssessmentOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                         <Item
                            title={t('sidebar.settings')}
                            to="/settings"
                            icon={<SettingsOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title={t('sidebar.onlineSupport')}
                            to="https://wa.me/16173475256"
                            target="_blank"
                            icon={<SupportAgentOutlinedIcon />}
                            selected={()=>{}}
                            setSelected={()=> {}}
                        />
                        <Divider variant="middle"/>
                        <Box display='flex' justifyContent='space-around'>
                        <Button startIcon={<LogoutOutlinedIcon />} fullWidth style={{maxWidth: '80%'}} onClick={signOut}>
                            {t('sidebar.logout')}
                        </Button>
                        </Box>
                        <Divider variant="middle"/>

                    </Box>
                </Menu>
                 <Box sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  ml:'30%',
                  mr:'30%'
                }}>
                    <Box>
                        <img src={usFlag}
                        style={{ width: "50%", height: "50%", cursor: 'pointer' }}
                        onClick={() => changeLanguage('en')}/>
                    </Box>
                    <Box>
                        <img src={spFlag}
                        style={{ width: "50%", height: "50%", cursor: 'pointer' }}
                        onClick={() => changeLanguage('es')}/>
                    </Box>
                    <Box>
                        <img src={brFlag}
                        style={{ width: "50%", height: "50%", cursor: 'pointer'}}
                        onClick={() => changeLanguage('pt')}/>
                    </Box>
                    </Box>
            </ProSidebar>
        </Box>
    )
}

export default Sidebar;